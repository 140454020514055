<template>
  <v-card>
    <form @submit.prevent="signIn">
      <v-img
        class="white--text ma-4"
        contain
        height="200px"
        src="../../assets/logo.jpg"
      />

      <v-card-title primary-title>
        Inloggen
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="error"
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          {{ error }}
        </v-alert>

        <v-row>
          <v-col>
            <v-text-field
              ref="email"
              v-model="email"
              label="E-mail"
              type="email"
              @input="error = ''"
            />

            <v-text-field
              ref="password"
              v-model="password"
              label="Wachtwoord"
              type="password"
              @input="error = ''"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              depressed
              type="submit"
            >
              Log in
            </v-btn>

            <v-btn
              :to="newPasswordRequestLink"
              depressed
              small
              class="ml-2"
            >
              <span>Wachtwoord vergeten</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  computed: {
    newPasswordRequestLink() {
      if (this.email) {
        return { name: 'newSetPasswordRequest', query: { email: this.email } };
      }

      return { name: 'newSetPasswordRequest' };
    },
  },
  mounted() {
    this.email = this.$refs.email.$el.querySelector('input').value;
    this.password = this.$refs.password.$el.querySelector('input').value;
  },
  methods: {
    signIn() {
      if (typeof this.email !== 'string') {
        this.error = 'Account met dit e-mail adres is niet gevonden.';

        return;
      }

      const url = `${config.VUE_APP_API_BASE_URL}/jwt`;
      const data = {
        email: this.email.trim().toLowerCase(),
        password: this.password,
      };

      axios({
        url,
        data,
        method: 'post',
      })
        .then((response) => {
          if (!response.data.isValid) {
            if (response.data.invalidReason === 'user not found') {
              this.error = 'Account met dit e-mail adres is niet gevonden.';
            } else if (response.data.invalidReason === 'password invalid') {
              this.error = 'Wachtwoord is incorrect.';
            } else {
              this.error = response.data.invalidReason || '';
            }

            return;
          }

          this.$store.dispatch('auth/setJwt', response.data.jwt);

          this.$router.push({ name: 'home' });
        });
    },
  },
};
</script>
